<template>
  <googlemaps-map ref="map" class="map" :center.sync="center" :zoom.sync="zoom">
    <!-- Marker -->
    <googlemaps-marker
      :title="title && null"
      :alt="title && null"
      :draggable="dragMark"
      :position="{ lat, lng }"
      @dragend="selectMarker"
      :aria-label="title && null"
    />
  </googlemaps-map>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    lat: {
      type: Number
    },
    lng: {
      type: Number
    },
    setPosition: {
      type: Function
    },
    dragMark: {
      type: Boolean,
      defualt: true,
      required: false
    },
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      zoom: 12,
      center: {}
    };
  },
  created() {
    this.center = {
      lat: this.lat,
      lng: this.lng
    };
  },
  watch: {
    lat: function(newVal, oldVal) {
      //console.log("changed");
      this.center.lat = newVal;
    },
    lng: function(newVal, oldVal) {
      this.center.lng = newVal;
    }
  },
  methods: {
    ...mapActions("layout", ["toggleSideNav"]),
    selectMarker(position) {
      if (position.latLng.lat() && position.latLng.lng()) {
        this.setPosition({
          lat: parseFloat(position.latLng.lat()),
          lng: parseFloat(position.latLng.lng())
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.demo {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
